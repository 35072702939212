import React, {useEffect, useState} from 'react';
import {useProviderProfile} from "../../../../ProviderProfileProvider";
import {Link} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";
import {SendRpc} from "../../../../rpcSender";
import {GetLoginsForBusinessRequest, GetLoginsForBusinessResponse, IApiUserProto} from "../../../../provider_api";
import {RpcState} from "../../../../RpcState";
import {findProvider} from "../Calendar/CalendarUtil";

export const Team = () => {

  const {effectiveUser, businessProfile} = useProviderProfile();

  const {getIdTokenClaims, user} = useAuth0();

  const isAdmin = () => {
    return effectiveUser?.businessRoles && effectiveUser.businessRoles.indexOf('business_admin') >= 0;
  }

  const [rpcState, setRpcState] = useState<RpcState>({state: "none"});

  const [users, setUsers] = useState<IApiUserProto[]>();

  useEffect(() => {

    const request = new GetLoginsForBusinessRequest({});

    SendRpc(getIdTokenClaims, 'get_logins', GetLoginsForBusinessRequest.encode(request).finish())
        .then(r => {
          const response = GetLoginsForBusinessResponse.decode(r);
          if (!response.okay) {
            setRpcState({state: 'error', message: 'unknown error fetching login'});
            return;
          }

          setUsers(response.provider);
          setRpcState({state: 'success'});

        })
        .catch(e => {
          setRpcState({state: 'error', message: e});
        })
  }, [businessProfile]);

  return <div className={'ProviderToolPage'}>

    <div className={'SectionHeader'}>
      <div className={'SectionHeaderRow'}>
        <h1>Team Members</h1>
        <Link to={'add'} state={{newService: true}}>
          <button className={'ActionButton'}>
            Add
          </button>
        </Link>
      </div>
    </div>

    <div className={'ProviderToolAreaScroll'}>
      <div className={'ProviderToolAreaContent'}>

        <table>
          <thead>
          <tr>
            <th>Name</th>
            <th>Login email</th>
            <th>Last Login</th>
            <th>Phone number</th>
            <th>Is Practitioner</th>
            <th>Actions</th>
            <th>Status</th>
          </tr>
          </thead>

          <tbody>
          {users?.map(user => {

            const userId = user.userId as string;
            const bio = findProvider(businessProfile?.providers, userId);
            
            return <tr key={userId}>
              <td>{bio?.firstName || user.profile?.firstName} {bio?.lastName ?? user.profile?.lastName}</td>
              <td>{user.lastLoginEmail}</td>
              <td>{user.lastLoginTimestamp ? new Date(user.lastLoginTimestamp as number).toLocaleString() : 'never'}</td>
              <td>{bio?.phone}</td>
              <td>{bio && bio.isPractitioner ? 'yes' : 'no'}</td>
              <td>{(isAdmin() && <Link to={`/team/edit/${userId}`}>Edit</Link>)}</td>
              <td>{(bio?.errors?.length == 0) ? (bio.disabled ? 'Disabled' : "Ok") :
                  <span className={'ErrorText'}>Please fix errors</span>}
              </td>
            </tr>
          })}
          </tbody>

        </table>


        {!businessProfile?.providers?.length && <div>
          <br/><br/>
          Click "Add" above to add your first team member!
        </div>}

      </div>
    </div>
  </div>

};

