import React, {useState} from "react";
import {space} from "../../../../provider_api";
import {SendRpc} from "../../../../rpcSender";
import {useAuth0} from "@auth0/auth0-react";
import {RpcState} from "../../../../RpcState";
import './Calendar.css';
import {EventModal} from "./EventModal";
import {useProviderProfile} from "../../../../ProviderProfileProvider";
import IEventProto = space.kenko.proto.IEventProto;
import IErrorProto = space.kenko.proto.IErrorProto;
import IEventInstanceProto2 = space.kenko.proto.IEventInstanceProto2;
import EventInstanceProto2 = space.kenko.proto.EventInstanceProto2;
import CreateEventRequest = space.kenko.proto.CreateEventRequest;
import CreateEventResponse = space.kenko.proto.CreateEventResponse;
import RepeatStrategyProto = space.kenko.proto.RepeatStrategyProto;

type Props = {

  // These come from the dragged calendar
  startTime: Date,
  endTime: Date,

  // The range of the current calendar view, this is used to request all
  // the instances of the newly created event. 
  calendarViewStart: Date,
  calendarViewEnd: Date,

  onCreate: (event: IEventProto) => void,
  onCancel: () => void,
}

export const NewEventModal = (props: Props) => {

  const {getIdTokenClaims} = useAuth0();
  const {business} = useProviderProfile();

  // The event that is getting edited
  const [errorContext, setErrorContext] = useState<IErrorProto[]>([]);
  const [rpcState, setRpcState] = useState<RpcState>();

  const sendCreateRpc = (event: IEventInstanceProto2) => {

    console.log(event)

    const request = new CreateEventRequest({
      event: event,
      clientEndTimestampMs: props.calendarViewStart.getTime(),
      clientStartTimestampMs: props.calendarViewEnd.getTime(),
    });

    SendRpc(getIdTokenClaims, "create_event",
        CreateEventRequest.encode(request).finish())
        .then(value => {
          const response = CreateEventResponse.decode(value);
          if (response.created) {
            props.onCreate(response.created);
          }
          console.log(response)
          setErrorContext(response.errors || []);
        })
        .catch(e => {
          alert('error ' + e);
          setRpcState({state: 'error', message: e})
        })
  };

  return <EventModal

      // This is the 'default' blank event
      instance={new EventInstanceProto2({
        businessId: business?.businessId,
        calendarId: '100',
        timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
        repeatStrategy: new RepeatStrategyProto({
          none: {},
        }),
        details: {
          startTimestamp: props.startTime.getTime(),
          endTimestamp: props.endTime.getTime(),
        }
      })}
      errors={errorContext}
      isEditing={false}
      onCreate={sendCreateRpc}
      onDelete={() => { /* noop, delete shouldn't be offered */}}
      onCancel={props.onCancel}
  />
};
