import React, {useState} from "react";
import {space} from "../../../../provider_api";
import {SendRpc} from "../../../../rpcSender";
import {useAuth0} from "@auth0/auth0-react";
import {RpcState} from "../../../../RpcState";
import './Calendar.css';
import {EventModal} from "./EventModal";
import IEventProto = space.kenko.proto.IEventProto;
import UpdateInstanceRequest = space.kenko.proto.UpdateInstanceRequest;
import UpdateInstanceResponse = space.kenko.proto.UpdateInstanceResponse;
import IErrorProto = space.kenko.proto.IErrorProto;
import IEventInstanceProto2 = space.kenko.proto.IEventInstanceProto2;

type Props = {

  // EventInstanceProto2 is a denormalized, flattened version of the event
  // and event instance that is much easier to deal with.
  existingEvent: IEventInstanceProto2

  // The range of the current calendar view, this is used to request all
  // the instances of the newly created event. 
  calendarViewStart: Date,
  calendarViewEnd: Date,

  // The update returns at least one, but maybe two series -- two if the
  // user chose 'this and future events', because that splits the original
  // event into two.
  onUpdated: (events: IEventProto[]) => void,
  onCancel: () => void,
}

export const EditEventModal = (props: Props) => {

  const {getIdTokenClaims} = useAuth0();

  // The event that is getting edited
  const [errorContext, setErrorContext] = useState<IErrorProto[]>([]);
  const [rpcState, setRpcState] = useState<RpcState>();

  const sendUpdateRpc = (updatedEvent: IEventInstanceProto2) => {

    // TODO::
    // Alert with an "update future instances" popup..

    sendRpc(new UpdateInstanceRequest({
      instance: updatedEvent,
      startTimestampMs: props.calendarViewStart.getTime(),
      endTimestampMs: props.calendarViewEnd.getTime(),
      updateFutureInstances: false
    }));
  }

  const sendDelete = () => {

    if (!window.confirm(`Really delete this instance '${props.existingEvent.details?.title}'?`)) {
      return;
    }

    sendRpc(new UpdateInstanceRequest({
      // Nulling out the details signifies a delete. Strange but true.
      // Maybe should fix this.
      instance: {
        ...props.existingEvent,
        details: null
      },
      startTimestampMs: props.calendarViewStart.getTime(),
      endTimestampMs: props.calendarViewEnd.getTime(),
      updateFutureInstances: false
    }));
  }

  const sendRpc = (request: UpdateInstanceRequest) => {

    // alert('creating request ' + request)
    console.log(request)

    SendRpc(getIdTokenClaims, "update_event",
        UpdateInstanceRequest.encode(request).finish())
        .then(value => {
          const response = UpdateInstanceResponse.decode(value);
          if (response.updatedEvent && response.updatedEvent.length > 0) {
            props.onUpdated(response.updatedEvent);
          }
          setErrorContext(response.errors || []);
        })
        .catch(e => {
          alert('error ' + e);
          setRpcState({state: 'error', message: e})
        })
  };

  return <EventModal
      instance={props.existingEvent}
      errors={errorContext}
      isEditing={true}
      onCreate={sendUpdateRpc}
      onDelete={sendDelete}
      onCancel={props.onCancel}
  />
};
