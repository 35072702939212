import {IApiBusinessProfileProto, space} from "./compiled";
import IBusinessLocationProto = space.kenko.proto.IBusinessLocationProto;

type Props = {
  business: IApiBusinessProfileProto | undefined,
  onSelect: ({location: IBusinessLocationProto, index: number}) => void;
}

export const ChooseLocation = (props: Props) => {

  return <div style={{display: 'flex', flexDirection: "column", textAlign: 'start'}}>
    <b>Select Service Location</b>
    <br/>

    {props.business?.locations?.map((location, index) => {
      return <button className={'BookingOverlaySelectable'} onClick={() => {props.onSelect({location: location, index: index})}}>
        <div><b>{location.locationName}</b></div>
        <div>{location.address}</div>
      </button>
    })}
  </div>

}