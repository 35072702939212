import {IApiBusinessProfileProto, space} from "../../provider_api";
import ProviderBioProto = space.kenko.proto.ProviderBioProto;

export const hasAtLeast1Service = (profile: IApiBusinessProfileProto | undefined | null) => {
  return profile?.practice?.services ? profile?.practice.services.length > 0 : false;
}

export const hasAtLeast1Provider = (profile: IApiBusinessProfileProto | undefined | null) => {
  if (!profile?.providers) {
    return false;
  }

  for (let i = 0; i < profile.providers.length; i++) {
    const provider = profile.providers[i] as ProviderBioProto;
    if (!provider.disabled && provider.isPractitioner) {
      return true;
    }
  }
  return false;
}

export const businessProfileHasErrors = (profile: IApiBusinessProfileProto | undefined | null) => {
  if (!profile?.errors) {
    return false;
  }

  return profile.errors.length > 0;
}

export const providersHaveErrors = (profile: IApiBusinessProfileProto | undefined | null) => {
  if (!profile?.providers) {
    return false;
  }

  for (let i = 0; i < profile.providers?.length; i++) {
    const provider = profile.providers[i] as ProviderBioProto;
    if (provider.errors.length > 0) {
      return true;
    }
  }
  return false;
}