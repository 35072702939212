import React, {useState} from 'react';
import {useProviderProfile} from "../../ProviderProfileProvider";
import './AdminHeader.css';
import {SendRpc} from "../../rpcSender";
import {useAuth0} from "@auth0/auth0-react";
import {AdminBusinessSearchRequest, AdminBusinessSearchResponse, IAdminBusinessSearchRequest} from "../../provider_api";
import IBusinessSearchResult = AdminBusinessSearchResponse.IBusinessSearchResult;
import {RpcState} from "../../RpcState";

export const AdminHeader = () => {

  const {getIdTokenClaims} = useAuth0();
  const {
    authenticatedUserRoles,
    business,
    businessProfile,
    setEffectiveBusinessId,
    setEffectiveUserId
  } = useProviderProfile();

  // The query is timestamped so out-of-order responses get handled correctly.
  const [query, setQuery] = useState('');

  const [queryResults, setQueryResults] = useState<IBusinessSearchResult[]>([]);

  // If there's an outstanding RPC, the latest query gets stuffed here.
  const [queuedQuery, setQueuedQuery] = useState<IAdminBusinessSearchRequest>();

  // Keep track so there's only one outstanding rpc at a time.
  const [rpcState, setRpcState] = useState<RpcState>({state: 'none'});

  const [focused, setFocused] = useState(false);

  const doQuery = (query: string) => {

    if (!query || query.trim().length == 0) {
      setQueryResults([]);
      return;
    }

    const request = new AdminBusinessSearchRequest({
      query: query
    });


    if (rpcState.state == 'pending') {
      setQueuedQuery(request);
    } else {
      sendRpc(request);
    }

  }

  const sendRpc = (request: IAdminBusinessSearchRequest) => {
    SendRpc(getIdTokenClaims, 'admin_business_search', AdminBusinessSearchRequest.encode(request).finish())
        .then(value => {
          const response = AdminBusinessSearchResponse.decode(value);

          if (response.okay) {
            setQueryResults(response.searchResult);
            console.log('query results', response.searchResult)
          }

          if (queuedQuery) {
            sendRpc(queuedQuery);
            setQueuedQuery(undefined)
          }

        })
        .catch(reason => {
          alert('rpc error ' + reason)
          setRpcState({state: 'error', message: reason})
        })
  }

  const renderSearchResult = (result: IBusinessSearchResult) => {
    return (<div className={'BusinessSearchResult'}
                 onClick={() => setEffectiveBusinessId(result.business?.businessId)}
                 key={result.business?.businessId || 0}>
      <div><b>{result.profile?.proto?.businessName || 'unnamed'}</b> ({result.business?.businessId})</div>
      <div>short url: {result.profile?.proto?.shortUrl || 'unset'}</div>
    </div>)
  };

  if (authenticatedUserRoles.indexOf('admin') < 0) {
    return <></>
  }

  return <div className={'AdminHeader'}>

    <div>You're an admin!</div>

    <div style={{flex: 1}}>
      {/* The admin user may or may not have a business entirely */}
      Business:&nbsp;
      {!businessProfile?.proto && <b>None</b>}
      {!!businessProfile?.proto && <><b>{businessProfile.proto.businessName || 'unnamed'}</b> ({business?.businessId})</>}
    </div>

    <div className={'VerticalFlex'}>
      <div className={'HorizontalFlex'}>
        search:&nbsp;
        <input type={'text'} value={query}
               onFocus={() => setFocused(true)}
               onBlur={() => {
                 // Keeps the blur from happening before a click on an item.
                 setTimeout(() => setFocused(false), 100);
               }}
               onChange={e => {
                 setQuery(e.target.value)
                 doQuery(e.target.value);
               }}/>
      </div>

      {/* this div is an anchor for the relative'y positioned overlay*/}
      <div>
        {queryResults && focused && <div className={'BusinessSearchResultOverlay'}>
          {queryResults.map(renderSearchResult)}
        </div>}
      </div>

    </div>

  </div>
}