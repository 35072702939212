import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';

import {BrowserRouter} from 'react-router-dom';
import {Auth0ProviderWithNavigate} from './auth0-provider-with-navigate';
import {ProviderProfileProvider} from "./ProviderProfileProvider";
import App from "./App/App";
import {AdminHeader} from "./App/AdminHeader/AdminHeader";

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
      <BrowserRouter>
        <Auth0ProviderWithNavigate>
          <ProviderProfileProvider>
            <App/>
          </ProviderProfileProvider>
        </Auth0ProviderWithNavigate>
      </BrowserRouter>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(null);
