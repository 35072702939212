import React, {useMemo} from 'react';
import './InputTypes.css';
import {LabeledDropdownInput} from "./LabeledDropdownInput";

type Props = {
  label: string;
  value: { hour: number, minute: number },
  error: string | null | undefined;
  onChange: (value: { hour: number, minute: number }) => void;
}

const toTimeString = (value: { hour: number, minute: number }) => {
  return value.hour.toFixed() + ':' + value.minute.toFixed(2);
}

const fromTimeString = (timeString: string) => {
  const split = timeString.split(':');
  return {hour: parseInt(split[0]), minute: parseInt(split[1])}
}


/**
 * This uses LabeledDropdownInput but adapts it for time inputs.
 * @param props
 * @constructor
 */
export const TimeInput15MinInterval = (props: Props) => {

  const options = useMemo(() => {
    const values: { value: string, label: string }[] = [];
    for (let h = 0; h < 24; h++) {
      for (let m = 0; m < 60; m += 15) {
        
        // lean on the javascript date to format the time pretty...
        const d = new Date();
        d.setHours(h);
        d.setMinutes(m);
        d.setSeconds(0);
        d.setMilliseconds(0);
        
        values.push({
          value: toTimeString({hour: h, minute: m}), 
          label: d.toLocaleTimeString(undefined, {hour: "numeric", minute: 'numeric'})
        });
      }
    }

    return values;
  }, [])
  

  return <LabeledDropdownInput label={props.label}
                               value={toTimeString(props.value)}
                               error={props.error}
                               options={options}
                               onChange={v => props.onChange(fromTimeString(v))}

  />

}