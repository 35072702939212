import React from 'react';
import './InputTypes.css';
import 'react-phone-number-input/style.css'
import '../../App/Styles.css'
import {fromDateString, toDateString} from "../../util/DateTimeUtils";

type Props = {
  label: string;
  helpText?: string; // an optional string underneath 
  value: {year: number, month: number, date: number};
  error?: string | null | undefined;
  onChange: (value: {year: number, month: number, date: number}) => void;
}

export const LabeledDateInput = (props: Props) => {

  return <div className={'VerticalFlex Flex1'}>
    <div className={'LabeledTextInputLabel'}>{props.label}</div>

    <input type={'date'} className={'LabeledTextAreaInput'}
           value={props.value ? toDateString(props.value) : ''}
           onChange={event => props.onChange(fromDateString(event.target.value))}
    />

    {props.helpText && <div className={'InputHelpText'}>{props.helpText}</div>}
    {props.error && <div className={'LabeledInputError'}>{props.error}</div>}
  </div>

}