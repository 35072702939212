import React, {useState} from 'react';
import {space, UpdateProviderSurveyRequest, UpdateProviderSurveyResponse} from "../../../provider_api";
import {useAuth0} from "@auth0/auth0-react";
import {SendRpc} from "../../../rpcSender";
import './NewProviderSurvey.css';
import {useNavigate} from "react-router-dom";
import {useProviderProfile} from "../../../ProviderProfileProvider";
import {
  apptLocationToString,
  sessionTypeToString,
  SurveyPage,
  surveyRoleToString
} from "../../../components/survey/SurveyComponents";
import {LabeledTextInput} from "../../../components/input/LabeledTextInput";
import {LabeledPhoneInput} from "../../../components/input/LabeledPhoneInput";
import {MultiSelect} from "../../../components/input/MultiSelectInput";
import {RadioSelect} from "../../../components/input/RadioSelectInput";
import {EditableComponentProps, EditableList} from "../../../components/input/EditableList";
import {EducationInputComponent} from "../Sections/Team/EducationInputComponent";
import {LicenseCredentialComponent} from "../Sections/Team/LicenseCredentialComponent";
import {LabeledAddressInput} from "../../../components/input/LabeledAddressInput";
import {clearError, filterErrors, findError} from "../../../components/input/InputTypes";
import ProviderSurveyProto = space.kenko.proto.ProviderSurveyProto;
import IProviderSurveyProto = space.kenko.proto.IProviderSurveyProto;
import EducationProto = space.kenko.proto.EducationProto;
import IEducationProto = space.kenko.proto.IEducationProto;
import LicenseOrCredentialsProto = space.kenko.proto.LicenseOrCredentialsProto;
import ILicenseOrCredentialsProto = space.kenko.proto.ILicenseOrCredentialsProto;
import SurveySessionType = space.kenko.proto.SurveySessionType;
import SurveyRole = space.kenko.proto.SurveyRole;
import SurveyApptLocations = space.kenko.proto.SurveyApptLocations;
import IErrorProto = space.kenko.proto.IErrorProto;
import SpecialtyProto = space.kenko.proto.SpecialtyProto;

const Survey = () => {

  const {business, setBusiness, setBusinessProfile} = useProviderProfile();

  const {getIdTokenClaims} = useAuth0();

  const [currentStep, setCurrentStep] = useState(0);

  // The results are kept as the survey proto. 
  const [results, setResults] = useState<IProviderSurveyProto>(
      business?.surveyProto ?? new ProviderSurveyProto);
  const [errors, setErrors] = useState<IErrorProto[]>([]);

  // A top-level error that sits near the "continue" button.
  const [validationError, setValidationError] = useState<string>();

  // If there was an RPC error sending the profile.
  const [updateProfileError, setUpdateProfileError] = useState('');

  const navigate = useNavigate();

  const serviceTypes = [
    {value: "Acupuncture", label: 'Acupuncture'},
    {value: "Traditional Chinese Medicine", label: 'Traditional Chinese Medicine'},
    {value: 'Diet & Nutrition', label: 'Diet & Nutrition'},
    {value: 'Massage Therapy', label: 'Massage Therapy'},
    {value: 'Sound Therapy', label: 'Sound Therapy'},
    {value: 'Meditation', label: 'Meditation'},
    {value: 'Naturopathy', label: 'Naturopathy'},
    {value: 'Homeopathy', label: 'Homeopathy'},
    {value: 'Yoga', label: 'Yoga'},
    {value: 'Chiropractic', label: 'Chiropractic'},
    {value: 'Physical Therapy', label: 'Physical Therapy'},
  ];

  const businessRoles = [
    SurveyRole.OFFICE_MANAGER_OR_STAFF,
    SurveyRole.PRACTITIONER,
    SurveyRole.BUSINESS_OWNER_AND_PRACTITIONER,
    SurveyRole.BUSINESS_OWNER_NON_PRACTITIONER,
    SurveyRole.OTHER
  ];

  const sessionTypes = [
    {value: SurveySessionType.PRIVATE_SESSIONS, label: 'Private 1:1 sessions'},
    {value: SurveySessionType.SEMI_PRIVATE_SESSIONS, label: 'Semi-private sessions (2+ clients)'},
    {value: SurveySessionType.GROUP_CLASSES, label: 'Group Classes'},
  ]

  const appointmentTypes = [
    {value: SurveyApptLocations.IN_PERSON_AT_BUSINESS, label: 'In-person at a business location(s)'},
    {value: SurveyApptLocations.VIRTUAL_APPOINTMENTS, label: 'Virtual appointments'},
    {value: SurveyApptLocations.IN_HOME_APPOINTMENTS, label: 'I travel to my clients (e.g., in-home appointments)'},
  ];

  const updateYearsExperience = (specialty: string, years: number) => {

    setResults(results => {
      return {
        ...results,
        specialty: results.specialty?.map(existing => {
          return existing.specialty == specialty ? new SpecialtyProto({
            specialty: specialty,
            yearsExperience: years
          }) : existing
        })
      };
    })
  }


  /**
   * @param surveyComplete false for incremental drafts; true will submit this for review.
   */
  const sendUpdateProviderSurveyRequest = (surveyComplete: boolean) => {
    // Build up the thing from the survey questions
    const request = UpdateProviderSurveyRequest.encode(
        UpdateProviderSurveyRequest.create({
          survey: results,
          surveyComplete: surveyComplete
        })
    ).finish();

    console.log('sending survey request', results);

    SendRpc(getIdTokenClaims, 'update_provider_survey', request)
        .then(value => {
          const response = UpdateProviderSurveyResponse.decode(value);
          if (!response.okay) {

            // Not okay, awful!!
            console.warn('[Survey] Profile send failure!');
            setUpdateProfileError('Server error updating profile');
            return;
          }

          console.info('[Survey] Profile send success!');
          setBusiness(response.business);

          // If the alpha user was auto-approved this might exist
          setBusinessProfile(response.profile);
          navigate('/')
        })
        .catch(e => {
          console.warn('[Survey] Profile send failure!', e);
          setUpdateProfileError(e);
        });
  }

  const surveyPages2: SurveyPage[] = [
    {
      pageHeaderOverride: '',
      content: <div className={'SurveyOpeningPage'}>
        <h1>Welcome to Kenko</h1>
        <p>
          We’re excited to have you join our mission to make holistic care more accessible. We know how important it
          is to treat the whole body and want to share these benefits with others. Let’s change the future of care,
          together!
        </p>
        <p>Thank you for joining us on this journey,</p>
        <p className={'SurveySignature'}>Carlie & Christine</p>
        <br/>
        <img src={require('../../../images/survey_welcome_slice@0.25x.jpg')}/>
      </div>
    },
    {
      title: "What's your business name?",
      subtitle: "This is the name that your clients will see. You can add your billing and legal name later.",
      content: <div className={'NewSurveyContentContainer'}>
        <LabeledTextInput label={'Business name'} inputType='text'
                          value={results.businessName}
                          error={findError(errors, 'businessName')}
                          onChange={v => {
                            setResults(results => {
                              return {...results, businessName: v};
                            })
                          }}/>
        <LabeledPhoneInput label={'Business phone number (if different from personal}'}
                           value={results.businessPhone}
                           error={findError(errors, 'businessPhone')}
                           onChange={v => {
                             setResults(results => {
                               return {...results, businessPhone: v};
                             })
                           }}/>
        <LabeledTextInput label={'Business email (if different from personal)'} inputType='text'
                          value={results.businessEmail}
                          error={findError(errors, 'businessEmail')}
                          onChange={v => {
                            setResults(results => {
                              return {...results, businessEmail: v};
                            })
                          }}/>
        <LabeledTextInput label={'Website (optional)'} inputType='text'
                          value={results.website}
                          error={findError(errors, 'website')}
                          onChange={v => {
                            setResults(results => {
                              return {...results, website: v};
                            })
                          }}/>
      </div>,
      validate: () => {
        if (!results.businessName || results.businessName.trim().length == 0) {
          setValidationError('Please check below for errors');
          setErrors([{path: 'businessName', error: 'Please enter a business name'}]);
          return false;
        }
        return true;
      }
    }, {
      title: 'What services do you offer?',
      subtitle: 'Select all that apply',
      content: <MultiSelect options={serviceTypes}
                            value={results.servicesOffered || []}
                            onChange={v => {
                              // NOTE NOTE NOTE!!! This also sets the specialties offered and initializes
                              // it with a year of zero!!!
                              setResults(results => {
                                return {
                                  ...results,
                                  servicesOffered: v,
                                  specialty: v.map(service => {
                                    return {
                                      specialty: service,
                                      yearsExperience: 0
                                    }
                                  })
                                };
                              })
                            }}/>,
      validate: () => {
        if (!results.servicesOffered?.length) {
          setValidationError('Please select at least one option');
          return false;
        }
        return true;
      },
    }, {
      title: 'What best describes your role in the business?',
      subtitle: 'By default, your account will have admin privileges (you can always ' +
          'change this later). If you are a practitioner, we can add your profile as a ' +
          'practitioner to the business.',
      content: <RadioSelect value={results.personsBusinessRole}
                            options={businessRoles.map(role => {
                              return {value: role, label: surveyRoleToString(role)}
                            })}
                            onChange={v => {
                              setResults(results => {
                                return {...results, personsBusinessRole: v};
                              })
                            }}/>,
      validate: () => {
        if (!results.personsBusinessRole) {
          setValidationError('Please select one option');
          return false;
        }
        return true;
      },
    }, {
      title: 'How many years of experience do you have for each specialty?',
      subtitle: 'If you are not a practitioner, please provide information regarding the most ' +
          'experienced practitioner in your business.',
      content: <table className={'SurveyExperienceTable'}>
        <tr>
          <th>Specialty</th>
          <th>Years of Experience</th>
        </tr>
        {results.specialty?.map((specialty, index) => {
          return <tr>
            <td>{specialty.specialty}</td>
            <td><LabeledTextInput label={''}
                                  value={specialty.yearsExperience?.toString() || '0'}
                                  inputType={'number'}
                                  error={findError(errors, `specialty.${index}`)}
                                  onChange={v => {
                                    updateYearsExperience(specialty.specialty as string, parseInt(v));
                                    setErrors(clearError(errors, `specialty.${index}`))
                                  }}/>

            </td>
          </tr>
        })}
      </table>,

      validate: () => {

        const errors: IErrorProto[] = [];

        if (results.specialty) {
          for (let i = 0; i < results.specialty.length; i++) {
            let yearsExperience = results.specialty[i].yearsExperience ?? 0;
            if (yearsExperience <= 0) {
              errors.push({
                path: `specialty.${i}`,
                error: 'Please enter a value of at least 1 year'
              })
            }
          }
        }
        setErrors(errors);
        return errors.length == 0;
      },
    },
    {
      title: 'Please share relevant information regarding your education and ' +
          'licenses/credentials.',
      subtitle: 'If you are not a practitioner, please provide information regarding the ' +
          'most experienced practitioner in your business.',
      content: <div className={'NewSurveyContentContainer'}>
        <EditableList label={'Education'}
                      value={results.education || []}
                      errors={filterErrors(errors, 'education.')}
                      tBuilder={() => new EducationProto()}
                      renderItem={(props: EditableComponentProps<IEducationProto>) => {
                        return <EducationInputComponent
                            value={props.value}
                            errors={props.errors}
                            onClearError={props.onClearError}
                            onChange={props.onChange}
                            onDelete={props.onDelete}/>
                      }}
                      onChange={v => {
                        setErrors(clearError(errors, 'education.general'));
                        setResults(results => {
                          return {...results, education: v}
                        })
                      }}
                      onClearError={pathToClear => {
                        setErrors(clearError(errors, 'education.' + pathToClear));
                      }}/>
        <EditableList label={'Licenses/Credentials'}
                      value={results.licenseCredentials || []}
                      errors={filterErrors(errors, 'license.')}
                      tBuilder={() => new LicenseOrCredentialsProto()}
                      renderItem={(props: EditableComponentProps<ILicenseOrCredentialsProto>) => {
                        return <LicenseCredentialComponent
                            value={props.value}
                            errors={props.errors}
                            onClearError={props.onClearError}
                            onChange={props.onChange}
                            onDelete={props.onDelete}/>
                      }}
                      onChange={v => {
                        setErrors(clearError(errors, 'license.general'));
                        setResults(results => {
                          return {...results, licenseCredentials: v}
                        })
                      }}
                      onClearError={pathToClear => {
                        setErrors(clearError(errors, 'license.' + pathToClear));
                      }}/>
      </div>,
      validate: () => {
        const validationErrors: IErrorProto[] = [];

        if (!results.education || results.education?.length == 0) {
          validationErrors.push({path: 'education.general', error: 'Please add at least one entry for education.'});

        } else {
          for (let i = 0; i < results.education?.length; i++) {
            const educationProto = results.education[i];
            if ((!educationProto.degree) || educationProto.degree?.trim().length == 0) {
              validationErrors.push({path: `education.${i}.degree`, error: 'Missing degree'});
            }
            if (!educationProto.yearCompleted ||
                educationProto.yearCompleted < 1920 || educationProto.yearCompleted > 2032) {
              validationErrors.push({path: `education.${i}.year`, error: 'Invalid year'});
            }
            if (!educationProto.school || educationProto.school?.trim().length == 0) {
              validationErrors.push({path: `education.${i}.school`, error: 'Missing school name'});
            }
          }
        }

        if (!results.licenseCredentials || results.licenseCredentials?.length == 0) {
          validationErrors.push({path: 'license.general', error: 'Please add at least license/credential.'});

        } else {
          for (let i = 0; i < results.licenseCredentials?.length; i++) {
            const licenseProto = results.licenseCredentials[i];
            if ((!licenseProto.licenseOrCredential) || licenseProto.licenseOrCredential?.trim().length == 0) {
              validationErrors.push({path: `license.${i}.license`, error: 'Missing information'});
            }
            if (!licenseProto.supportDocumentationUrl?.length) {
              validationErrors.push({path: `license.${i}.documentation`, error: 'Missing documentation'});
            }
          }
        }
        setErrors(validationErrors);
        if (validationErrors.length > 0) {
          setValidationError('Please check for errors below');
        }

        return validationErrors.length == 0;
      }
    },
    {
      title: 'What type of sessions do you offer?',
      subtitle: 'Select all that apply.',
      content: <MultiSelect options={sessionTypes}
                            value={results.sessionTypes || []}
                            onChange={v => {
                              setResults(results => {
                                return {...results, sessionTypes: v};
                              })
                            }}/>,
      validate: () => {
        if (!results.sessionTypes?.length) {
          setValidationError('Please select at least one option');
          return false;
        }
        return true;
      },
    },
    {
      title: 'Where do you offer your services?',
      subtitle: 'Select all that apply. Our platform supports multiple office locations as well as online-only businesses.',
      content: <MultiSelect options={appointmentTypes}
                            value={results.appointmentLocations || []}
                            onChange={v => {
                              setResults(results => {
                                return {...results, appointmentLocations: v};
                              })
                            }}/>,
      validate: () => {
        if (!results.appointmentLocations?.length) {
          setValidationError('Please select at least one option');
          return false;
        }
        return true;
      },
    },
    {
      title: 'Where are you located?',
      subtitle: 'Add your business address(es) so clients can easily find you.',
      content: <LabeledAddressInput label={'Business address'}
                                    value={results.addresses?.formattedAddress || ''}
                                    error={''}
                                    onChange={v => {
                                      setResults(results => {
                                        return {
                                          ...results, addresses: {
                                            lat: v?.geometry?.location?.lat(),
                                            lng: v?.geometry?.location?.lng(),
                                            formattedAddress: v?.formatted_address
                                          }
                                        };
                                      })
                                    }}/>,
      validate: () => {
        if (!results.addresses) {
          setValidationError('Please enter your business address');
          return false;
        }
        return true;
      }
    },
    {
      pageHeaderOverride: 'CONFIRMATION',
      title: 'Please confirm your information.',
      content: <div className={'SurveyOpeningPage'}>
        <h3>BUSINESS INFORMATION</h3>
        <b>Business name</b>
        {results.businessName}
        <br/><br/>
        <b>Business phone number</b>
        {results.businessPhone}
        <br/><br/>
        <b>Business email</b>
        {results.businessEmail}
        <br/><br/>
        <b>Business phone number</b>
        {results.website}

        <hr/>

        <h3>SERVICES</h3>
        <b>Services offered</b>
        {results.servicesOffered?.map(service => {
          return <>{service}<br/> </>
        })}

        <hr/>

        <h3>ADMIN INFORMATION</h3>
        <b>User business role</b>
        {surveyRoleToString(results.personsBusinessRole as SurveyRole)}

        <hr/>

        <h3>EXPERIENCE</h3>
        <b>Specialty and years of experience</b>
        {results.specialty?.map(specialty => {
          return <>{specialty.specialty} - {specialty.yearsExperience} year(s)<br/></>
        })}

        <hr/>

        <h3>EDUCATION / CREDENTIALS</h3>
        <b>Education</b>
        {results.education?.map(education => {
          return <>{education.degree}, {education.school} ({education.yearCompleted})<br/></>
        })}
        <br/><br/>
        <b>License</b>
        {results.licenseCredentials?.map(license => {
          return <div className={'VerticalFlex'}>
            {license.licenseOrCredential}
            {license.supportDocumentationUrl?.map(url => {
              return <img style={{maxWidth: 400}} alt={'credential'} src={url}/>
            })}
          </div>
        })}

        <hr/>

        <h3>SESSION INFORMATION</h3>
        <b>Sessions offered</b>
        {results.sessionTypes?.map(sessionType => {
          return <>{sessionTypeToString(sessionType)}<br/></>
        })}
        <br/>
        <b>Session location(s)</b>
        {results.appointmentLocations?.map(location => {
          return <>{apptLocationToString(location)}<br/></>
        })}

        <hr/>

        <h3>BUSINESS LOCATION INFORMATION</h3>
        <b>Business address</b>
        {results.addresses?.formattedAddress}

        <br/><br/>
      </div>
    },
  ]

  const getNextVisibleStep = () => {
    return currentStep + 1;
  };

  const getPreviousVisibleStep = () => {
    return currentStep - 1;
  };

  const page = surveyPages2[currentStep];

  /**
   *
   * @param results the most recent survey results--- make sure not to grab the state
   *                variable if you have mutated it in this frame! pass a new copy.
   *                this is important when using radio buttons
   */
  const advancePage = () => {
    const nextStep = getNextVisibleStep();

    if (page.validate && !page.validate()) {
      return;
    }

    // We were able to advance the page, clear out this error.
    setValidationError(undefined);

    if (nextStep < surveyPages2.length) {
      // There's still another page to go. Advance the step
      setCurrentStep(nextStep);

      // Save the draft. The first page is just a welcome and doesn't send anything
      if (currentStep > 0) {
        sendUpdateProviderSurveyRequest(false);
      }

    } else {

      // This is the last step! Kick off the RPC. Do not advance the page.
      sendUpdateProviderSurveyRequest(true);
    }
  };

  const goBackAPage = () => {
    // This just clears out the RPC error when going back
    setUpdateProfileError('');
    setCurrentStep(getPreviousVisibleStep());
    setValidationError(undefined);
  };

  // This is a null parameter if there is no next step available which hides the button
  const onBackPress = () => {
    goBackAPage();
  };

  // This gets happen on 'OK' press AND 'enter' press in text box.
  const onContinue = () => {

    // if (!page.isValidValue(surveyResults[page.name])) {
    //   setValidationError(page.validationMessage);
    // } else {

    // It's okay to use the state variable to advance a page here because
    // there is a re-render between the time the users clicks a value and
    // hits the continue button.
    advancePage();
    // }
  };

  return (<>

    { /*progress bar*/}
    <div className={'SurveyProgressBar'}>
      {new Array(5).map((_, i) => {
        return (
            <div key={i} className={currentStep > i ?
                'SurveyProgressBarItem SurveyProgressBarItemSelected' :
                'SurveyProgressBarItem SurveyProgressBarItemNotSelected'}/>
        );
      })}
    </div>

    <div className={'AppSection WhiteSection'}>
      <div className={'AppSectionContent'}>
        <div className='SurveySection'>

          <div className={'SurveyHeader'}>
            <div className={'SurveyHeaderLeft'}>{page.pageHeaderOverride ?? 'ACCOUNT SETUP'}</div>

            {currentStep > 0 &&
                // Not every page has a back button      
                <div className={'SurveyHeaderButtonContainer'}>
                  <button className={'ActionButton ActionButtonSecondary'}
                          onClick={() => onBackPress()}>Back
                  </button>
                </div>
            }

            <div className={'SurveyHeaderButtonContainer'}>
              <button className='ActionButton' onClick={onContinue}>
                {(currentStep == 0) ? 'Get Started' :
                    (currentStep == surveyPages2.length - 1) ? 'Done' :
                        'Continue'}
              </button>
            </div>

          </div>

          {validationError &&
              <div className={'SurveyErrorHeader'}>
                <div className={'ErrorText'}>{validationError}</div>
              </div>
          }


          {page.title && <span className={'SurveyTitle'}>{page.title}</span>}

          {page.subtitle && <div className={'SurveySubtitle'}>{page.subtitle}</div>}

          {/* This renders the specific widget for the input [checklist, radio, text, etc.] */}
          {page.content}

          {updateProfileError &&
              (<div className={'SurveyError'}>Error building your profile: {updateProfileError.toString()}</div>)
          }
        </div>
      </div>

    </div>
  </>);
};

export default Survey;
