import {IApiBusinessProfileProto, space} from "./compiled";
import IServiceProto = space.kenko.proto.IServiceProto;

type Props = {
  business: IApiBusinessProfileProto | undefined,
  onSelect: (service: IServiceProto) => void;
}

export const ChooseService = (props: Props) => {

  return <div style={{display: 'flex', flexDirection: "column", textAlign: 'start'}}>
    <b>Select Service Type</b>
    <br/>
    {props.business?.practice?.services?.map(service => {
      return <button className={'BookingOverlaySelectable'} onClick={() => {props.onSelect(service)}}>
        <div><b>{service.name} ({service.lengthMinutes} min)</b></div>
        <div>{service.description}</div>
      </button>
    })}
  </div>

}