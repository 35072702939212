import {IBookAppointmentResponse} from "./compiled";
import React from "react";

// TODO I think this is just a horrible hack to get it into rollup
import icon_clock from './icon_clock.png';
import icon_calendar from './icon_calendar.png';
import icon_map_pointer from './icon_map_pointer.png';


// This gets passed along in the router
type Props = {
  response: IBookAppointmentResponse,
  onDone: () => void,
}

export const BookingComplete = (props: Props) => {

  const appointment = props.response.appointment
  const startTime = new Date(appointment?.startTimeMs as number);
  const endTime = new Date(appointment?.endTimeMs as number);
  const appointmentLength = (endTime.getTime() - startTime.getTime()) / 60000;

  return (<>
        <div className={'ConfirmationBottomSection'}>

          <div className={'ConfirmationRow'}>
            <div className={'ConfirmationRowLeftIcon'}>
              <img className={'ConfirmationRowLeftImage'} src={icon_calendar}/>
            </div>
            <div className={'ConfirmationRowRight'}>
              <div><b>{startTime.toLocaleDateString(undefined,
                  {weekday: 'long', month: 'short', day: 'numeric'})}
                &nbsp;at&nbsp;
                {startTime.toLocaleTimeString(undefined,
                    {hour: 'numeric', minute: 'numeric', timeZoneName: 'short'}
                )}
              </b></div>
              <div>{appointment?.serviceName} ({appointmentLength} min)</div>
            </div>
          </div>

          <div className={'ConfirmationRow'}>
            <div className={'ConfirmationRowLeftIcon'}>
              <img className={'ConfirmationRowLeftImage'} src={icon_map_pointer}/>
            </div>
            <div className={'ConfirmationRowRight'}>
              <div>{appointment?.address}</div>
            </div>
          </div>

          <div className={'ConfirmationRow'}>
            <div className={'ConfirmationRowLeftIcon'}>
              <img className={'ConfirmationRowLeftImage'} src={icon_clock}/>
            </div>
            <div className={'ConfirmationRowRight'}>
              <div>{appointmentLength} min · {appointment?.price?.price ? `${appointment.price.price}` : 'FREE'}</div>
            </div>
          </div>

          {/*<div className={'ConfirmationButton ConfirmationButtonBlack'}>Add to Calendar</div>*/}
          {/*<div className={'ConfirmationButton'}>Book Another Appointment</div>*/}
          {/*<div className={'CancelButton'}>Cancel appointment</div>*/}
        </div>
      </>
  );
}