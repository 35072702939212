
export const getBookingHost = () => {
  if (window.location.host.startsWith("localhost")) {
    return "http://localhost:3001";
  } else if (window.location.host.endsWith("thymebook.com")) {
    return "https://thymebook.com";
  }

  return "https://kenko.space";
}

export const getProviderHost = () => {
  if (window.location.host.startsWith("localhost")) {
    return "http://localhost:3002";
  } else if (window.location.host.endsWith("thymebook.com")) {
    return "https://provider.thymebook.com";
  }

  return "https://provider.kenko.space";
}
