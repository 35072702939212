import React, {ReactNode, useEffect, useState} from 'react';
import './BookingOverlay.css'
import {ChooseTime} from "./ChooseTime";
import {ConfirmBooking} from "./ConfirmBooking";
import {
  GetProviderProfileRequest,
  GetProviderProfileResponse,
  IApiBusinessProfileProto,
  IBookAppointmentResponse,
  IClientAppointmentProto,
  IClientServiceProto,
  space
} from "./compiled";
import {IdToken} from "@auth0/auth0-spa-js";
import {BookingComplete} from "./BookingComplete";
import {SendRpc} from "../util";
import {RpcState} from "../util/RpcState";
import {ChooseService} from "./ChooseService";
import {ChooseLocation} from "./ChooseLocation";
import {ChoosePractitioner} from "./ChoosePractitioner";
import IProviderBioProto = space.kenko.proto.IProviderBioProto;
import IServiceProto = space.kenko.proto.IServiceProto;
import IBusinessLocationProto = space.kenko.proto.IBusinessLocationProto;
import IPriceProto = space.kenko.proto.IPriceProto;

export type Props = {
  getIdToken: () => Promise<IdToken | undefined>;

  // The version is here in case the use is viewing a draft or
  // otherwise unpublished version. If unset, the live version is used. 
  businessId: string,
  version?: string,

  // This is optional, if specified the view filters based on this location.
  location?: number,

  // Also optional, if unset there's a screen to select a service.
  serviceSku?: string;

  // Optional, if a provider was chosen already
  providerId?: string,

  onCancel: () => void;

  // This happens when the RPC succeeds; user may not have closed
  // the modal yet! It should update the list of appointments.
  onAppointmentsUpdated: (appointments: IClientAppointmentProto[]) => void;
}

/**
 * 1. Select service (skip if only one or already specified)
 * 2. Choose location (skip if only one or already specified) -- WEIRD IF VIRTUAL. So this comes AFTER location.
 * 3. Select practitioner (or any)
 * 4. Show times (for 1, or all if not selected).
 *
 * There's an initial fetch to figure out which locations, services,
 * and practitioners are available at the office.
 *
 * Once the location, service, and (optionally) practitioner are selected,
 * then the times are fetched.and
 *
 * @param props
 * @constructor
 */
export const BookingOverlay: React.FC<Props> = (props) => {

  const [location, setLocation] = useState<{ location: IBusinessLocationProto, index: number }>();
  const [service, setService] = useState<IServiceProto>();
  const [practitioner, setPractitioner] = useState<IProviderBioProto>();
  const [timestamp, setTimestamp] = useState<number>();

  const [rpcState, setRpcState] = useState<RpcState>({state: 'none'})
  const [business, setBusiness] = useState<IApiBusinessProfileProto>();

  useEffect(() => {

    console.log('Booking Overlay props', props)
    
    setRpcState({state: 'pending'});
    SendRpc(props.getIdToken, 'get_business_details',
        GetProviderProfileRequest.encode(new GetProviderProfileRequest({
          businessId: props.businessId,
          version: props.version,
        })).finish())
        .then(responseBytes => {
          let response = GetProviderProfileResponse.decode(responseBytes);
          if (!response.okay || !response.business) {
            setRpcState({state: 'error', message: 'Error loading this provider'});
            return;
          }
          setRpcState({state: 'success'});
          setBusiness(response.business);
        })
        .catch(e => {
          setRpcState({state: 'error', message: e.toString()});
        })
  }, [])

  useEffect(() => {

    if (!business) {
      setService(undefined);
      setLocation(undefined);
      setPractitioner(undefined);
      setTimestamp(undefined);
      return;
    }

    // This chooses the defaults (if any!) based on how the user came into this overlay.
    // They might have selected a generic "Book now!" or "Book this service" or even
    // "Book with Joe". So we need to set those as the default.
    if (props.serviceSku && business.practice?.services) {
      for (let i = 0; i < business.practice.services.length; i++) {
        if (business.practice.services[i].sku == props.serviceSku) {
          setService(business.practice.services[i]);
        }
      }
    }

    if (props.providerId && business.providers) {
      for (let i = 0; i < business.providers.length; i++) {
        if (business.providers[i].providerId == props.providerId) {
          setPractitioner(business.providers[i]);
        }
      }
    }

  }, [business]);

  const [bookResponse, setBookResponse] = useState<IBookAppointmentResponse>();

  // Price is available only if both service and provider are selected. 
  const getPrice = (): IPriceProto | undefined | null => {
    if (!practitioner || !service || !service.offeredBy) {
      return null
    }

    for (let i = 0; i < service.offeredBy.length; i++) {
      const offeredBy = service.offeredBy[i];
      if (offeredBy.providerId == practitioner.providerId) {
        return offeredBy.price;
      }
    }

    return null;
  }

  /**
   *
   * @param things if title is unspecified, uses 'Book Appointment'
   */
  const getDefaultPageView = (things: { title?: string, closeButtonTitle?: string, bottom: ReactNode }) => {
    return <div className={'BookingOverlayModal'}>
      <div className={'BookingOverlay'}>

        <div className={'BookingOverlayTopRow BookingOverlaySectionMargin HorizontalCenteredFlex'}>
          <div style={{flex: 1}}><h1>{things.title || 'Book Appointment'}</h1></div>
          <button className={'BookingOverlayClose'}
                  onClick={props.onCancel}>{things.closeButtonTitle || 'Cancel'}</button>
        </div>
        {things.bottom}
      </div>
    </div>
  }

  const getTabbedBottomSection = (things: { leftSide: ReactNode, content: ReactNode }) => {
    return <div className={'BookingOverlayBottomRow'}>
      {things.leftSide}
      <div className={'BookingOverlayBottomRight BookingOverlaySectionMargin'}>
        {things.content}
      </div>
    </div>
  }


  const getLeftSide = () => {

    if (!practitioner && !service && !location && !timestamp) {
      
      // Nothing has been selected! In this case, don't show the left nav at all.
      // Once the service is selected, then show the left nav.
      return <></>
    }
    
    // Special case when there's ONLY a practitioner selected -- you get a special bio on 
    // the left side. This is to show credentials in a multi-practitioner office.
    if (practitioner && !service && !location && !timestamp) {
      return <div className={'BookingOverlayBottomLeft BookingOverlaySectionMargin'}>
        <div className={'SpecialProviderBio'}>
          <div className={'SpecialProviderBioTitleRow'}>
            <img className={'SpecialProviderBioPhoto'} src={practitioner.imageUrls ? practitioner.imageUrls[0] : ''}/>
            <div>
              <div><b>{practitioner.firstName} {practitioner.lastName}</b></div>
              <div>{practitioner.jobTitle}</div>
            </div>
          </div>
          <p>{practitioner.bio}</p>

          <p style={{marginBlockEnd: 0, textDecoration: 'underline'}}>Education</p>
          <ul style={{marginBlockStart: 0}}>
            {practitioner.education?.map(e => {
              return <li>{e.school}, {e.degree}</li>
            })}
          </ul>

          <p style={{marginBlockEnd: 0, textDecoration: 'underline'}}>Credentials</p>
          <ul style={{marginBlockStart: 0}}>
            {practitioner.credentials?.map(credential => {
              return <li>{credential.licenseOrCredential}</li>
            })}
          </ul>
        </div>

        <button className={'BookingChangeLink'} onClick={() => {
          setPractitioner(undefined);
          setTimestamp(undefined);
        }}>Change practitioner
        </button>
      </div>
    }


    return <div className={'BookingOverlayBottomLeft BookingOverlaySectionMargin'}>
      {service && <div>
        <div><b>{service.name} ({service.lengthMinutes} min)
          {getPrice() && ` · $${getPrice()?.price}.00`}</b></div>
        <button className={'BookingChangeLink'} onClick={() => {
          setService(undefined);
          setTimestamp(undefined);
        }}>Change service
        </button>
      </div>}

      {location && <div>
        <div><b>{location.location.locationName}</b></div>
        <div>{location.location.address}</div>
        <button className={'BookingChangeLink'} onClick={() => {
          setLocation(undefined);
          setTimestamp(undefined);
        }}>Change location
        </button>
      </div>}

      {practitioner && <div>
        <div><b>{practitioner.firstName} {practitioner.lastName}</b></div>
        <div>{practitioner.jobTitle}</div>
        <button className={'BookingChangeLink'} onClick={() => {
          setPractitioner(undefined);
          setTimestamp(undefined);
        }}>Change practitioner
        </button>
      </div>}

      {timestamp && <div>
        <div><b>{new Date(timestamp).toLocaleString(undefined, {
          dateStyle: 'long', timeStyle: 'short'
        })}</b></div>
        <button className={'BookingChangeLink'} onClick={() => setTimestamp(undefined)}>Change date/time</button>
      </div>}
    </div>
  }

  if (rpcState.state == 'pending') {
    return getDefaultPageView({bottom: <div className={'BookingOverlayBottomRow BookingOverlaySectionMargin'}>LOADING</div>});
  }

  if (rpcState.state == "error") {
    return getDefaultPageView({bottom: <div className={'BookingOverlayBottomRow BookingOverlaySectionMargin'}>ERROR: {rpcState.message}</div>});
  }

  // * 2. Select service (skip if only one or already specified) -- Choosing "virtual" location is awkward!
  // * 1. Choose location (skip if only one or already specified)
  // * 3. Select practitioner (or any)
  // * 4. Show times (for 1, or all if not selected).

  if (!service) {
    return getDefaultPageView({
      bottom: getTabbedBottomSection({
        leftSide: getLeftSide(),
        content: <ChooseService business={business} onSelect={setService}/>
      })
    });
  }


  if (!location) {
    return getDefaultPageView({
      bottom: getTabbedBottomSection({
        leftSide: getLeftSide(),
        content: <ChooseLocation business={business} onSelect={setLocation}/>
      })
    });
  }

  if (!practitioner) {
    return getDefaultPageView({
      bottom: getTabbedBottomSection({
        leftSide: getLeftSide(),
        content: <ChoosePractitioner locationFilter={location.location}
                                     serviceFilter={service}
                                     business={business}
                                     onSelect={setPractitioner}/>
      })
    });
  }


  if (!timestamp) {
    return getDefaultPageView({
      bottom: getTabbedBottomSection({
        leftSide: getLeftSide(),
        content: <ChooseTime businessId={props.businessId}
                             serviceSku={service.sku || ''}
                             providerId={practitioner.providerId as string}
                             location={location.index}
                             onCancel={props.onCancel}
                             onSelected={setTimestamp}/>
      })
    });
  }

  if (!bookResponse) {
    return getDefaultPageView({
      bottom: getTabbedBottomSection({
        leftSide: getLeftSide(),
        content: <ConfirmBooking
            getIdToken={props.getIdToken}
            businessId={props.businessId}
            provider={practitioner}
            service={service}
            onComplete={response => {
              if (response.updatedProfile?.appointments) {
                props.onAppointmentsUpdated(response.updatedProfile.appointments);
              }
              setBookResponse(response)
            }}
            appointmentTime={timestamp}
            isVirtual={false} // todo....
            locationIndex={location.index}
            onCancel={props.onCancel}/>
      })
    })
  }

  return getDefaultPageView({
    title: 'Appointment Confirmed 🎉',
    closeButtonTitle: 'Close',
    bottom: <div className={'BookingOverlayBottomRow BookingOverlaySectionMargin'}>
      <BookingComplete response={bookResponse} onDone={props.onCancel}/>
    </div>
  });
}