import {IApiBusinessProfileProto, space} from "./compiled";
import IBusinessLocationProto = space.kenko.proto.IBusinessLocationProto;
import {space} from "../../dist";
import IServiceProto = space.kenko.proto.IServiceProto;
import IProviderBioProto = space.kenko.proto.IProviderBioProto;

type Props = {
  
  // these potentially filter the list of providers shown...
  locationFilter: IBusinessLocationProto,
  serviceFilter: IServiceProto | undefined,
  
  business: IApiBusinessProfileProto | undefined,
  onSelect: (bio: IProviderBioProto) => void;
}


const providerOffersService = (service: IServiceProto, providerId: string | null | undefined) => {
  
  if (!service.offeredBy || !providerId) {
    return false;
  }
  
  for (let i = 0; i < service.offeredBy.length; i++) {
    if (service.offeredBy[i].providerId == providerId) {
      return true;
    }
  }
  
  return false;
}

export const ChoosePractitioner = (props: Props) => {


  return <div style={{display: 'flex', flexDirection: "column", textAlign: 'start'}}>
    <b>Select Practitioner</b>
    <br/>

    {props.business?.providers?.map((provider) => {
      
      if (!provider.isPractitioner) {
        return null;
      }
      
      if (props.serviceFilter && !providerOffersService(props.serviceFilter, provider.providerId)) {
        return null;
      }
      
      // TODO - location filter for provider...
      
      return <button className={'BookingOverlaySelectable'} onClick={() => {props.onSelect(provider)}}>
        <div><b>{provider.firstName} {provider.lastName}</b></div>
        <div>{provider.jobTitle}</div>
      </button>
    })}
  </div>

}