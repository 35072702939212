import React, {LegacyRef, useRef, useState} from 'react';
import axios, {AxiosRequestConfig} from 'axios';
import {useAuth0} from "@auth0/auth0-react";

type Props = {
  locationName: string,
  imageUrl: string[] | null | undefined,
  error?: string | null | undefined,
  onChange: (url: string[]) => void,
}

export const PhotoUpload = (props: Props) => {

  const [error, setError] = useState<string>();
  const {getIdTokenClaims} = useAuth0()

  const [uploadProgress, setUploadProgress] = useState(0);

  const fileUploadRef = useRef<HTMLInputElement>(null);

  const host = window.location.host

  /**
   * Selecting a file automatically uploads it to cloud storage and then renders the
   * image from that hosting URL.
   */
  function handleChange(file: File | undefined) {
    console.log(file)

    if (!file) {
      console.log('no file selected')
      return;
    }

    if (file.size > (5 * 1024 * 1024)) {
      setError('Image exceeds max size of 5 MB')
      return;
    }

    setError(undefined);

    getIdTokenClaims()
        .then(token => {

          let url = 'http://localhost:8082/upload';
          if (host.endsWith("provider.thymebook.com")) {
            url = 'https://provider-1096702705153.us-central1.run.app/upload'
          }

          const formData = new FormData();
          formData.append('file', file);
          const config: AxiosRequestConfig = {
            headers: {
              'Authentication': token?.__raw ?? '',
              'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: (progressEvent) => {
              setUploadProgress(Math.round((progressEvent.loaded * 100) / (progressEvent.total ?? file.size)));
            },
          };
          axios.post(url, formData, config)
              .then((response) => {
                console.log(response.data);
                // Add the new one at the end.
                props.onChange(props.imageUrl ? [...props.imageUrl, response.data] : [response.data]);
              })
              .catch((error) => {
                console.error(error.toString());
                setError(error.toString());
              })
              .finally(() => {
                setUploadProgress(0);
              });
        })
        .catch(e => {
          setError("Auth error. " + e.toString());
        });
  }

  const singlePhoto = (url: string, index: number) => {
    return <div className={'PhotoUploadSinglePhoto'}>
      <div onClick={() => {
        if (!props.imageUrl) {
          return;
        }

        props.onChange(props.imageUrl.filter((url, i) => {
          return index != i;
        }));

      }} className={'PhotoUploadClose'}>
        <img className={'PhotoUploadCloseImage'} src={require('../../images/icons/close.png')} alt="Delete"/>
      </div>

      <img className={'PhotoUploadPhoto'} src={url} alt="Uploaded content"/>
    </div>
  }

  return <div className={'VerticalFlex Flex1'} style={{maxWidth: '100%'}}>
    <div className={'LabeledTextInputLabel'}>{props.locationName} photo(s)</div>

    <div className={'PhotoUploadCarouselContainer'}>
      <div className={'PhotoUploadCarousel'}>

        {props.imageUrl?.map(singlePhoto)}

        {/* It's hard to style the file input because browsers are weird, but this is easy! */}
        <button className={'PhotoUploadAdd'} onClick={() => fileUploadRef.current?.click()}>
          <img src={require('../../images/icons/add_photo.png')}/>
          <span>Add more photos</span>
        </button>

        <input ref={fileUploadRef} type="file" style={{display: 'none'}} onChange={(e) => {
          if (e.target.files && e.target.files.length > 0) {
            handleChange(e.target.files[0]);
          } else {
            handleChange(undefined);
          }
        }}/>
      </div>
    </div>
    {uploadProgress > 0 && <div>
      <progress value={uploadProgress} max="100"/>
      {uploadProgress}%</div>}
    {error && <div className={'LabeledInputError'}>{error}</div>}
    {props.error && <div className={'LabeledInputError'}>{props.error}</div>}
  </div>
};

