import React, {useState} from 'react';
import './InputTypes.css';
import 'react-phone-number-input/style.css'
import '../../App/Styles.css'

type Props = {
  label: string;
  helpText?: string; // an optional string underneath 

  value?: string | null | undefined;
  error?: string | null | undefined;

  suggestions: string[],

  onChange: (value: string) => void;
  inputType: 'text' | 'tel' | 'email' | 'textarea' | 'number'
  disabled?: boolean,
}

export const LabeledTextInputWithSuggestions = (props: Props) => {

  const [focused, setFocused] = useState(false);

  return <div className={'VerticalFlex Flex1'}>
    <div className={'LabeledTextInputLabel'}>{props.label}</div>

    <input className={'LabeledTextInput'} type={props.inputType}
           onFocus={() => setFocused(true)}
           onBlur={() => {
             // Keeps the blur from happening before a click on an item.
             setTimeout(() => setFocused(false), 100);
           }}
           value={props.value || ''}
           disabled={props.disabled ?? false}
           onChange={event => props.onChange(event.target.value)}/>

    {/* this div is an anchor for the relatively positioned overlay*/}
    <div style={{position: "relative"}}>
      {props.suggestions && focused && <div className={'SearchResultOverlay'}>
        {props.suggestions.map((suggestion) => {
          return <div className={'SearchResult'} onClick={() => {
            props.onChange(suggestion);
          }}>{suggestion}</div>
        })}
      </div>}
    </div>

    {props.helpText && <div className={'InputHelpText'}>{props.helpText}</div>}
    {props.error && <div className={'LabeledInputError'}>{props.error}</div>}
  </div>

}