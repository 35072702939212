import {space} from "../provider_api";

export const toDateString = (date: {year: number, month: number, date: number}) => {
  // always in yyyy-mm-dd
  return `${date?.year}-${String(date?.month).padStart(2, '0')}-${String(date?.date).padStart(2, '0')}`
}

export const fromDateString = (dateString: string) => {
  // always in yyyy-mm-dd
  const parts = dateString.split('-')
  return {
    year: parseInt(parts[0]),
    month: parseInt(parts[1]),
    date: parseInt(parts[2])
  }
}
