import React, {useEffect, useMemo} from 'react';
import {useProviderProfile} from "../../../../ProviderProfileProvider";
import {SendRpc} from "../../../../rpcSender";
import {space} from "../../../../provider_api";
import GetEventsRequest = space.kenko.proto.GetEventsRequest;
import GetEventsResponse = space.kenko.proto.GetEventsResponse;
import {useAuth0} from "@auth0/auth0-react";
import {useState} from "react";
import IEventProto = space.kenko.proto.IEventProto;
import './Dashboard.css';
import IEventInstanceProto = space.kenko.proto.IEventInstanceProto;
import {spanToMinutes} from "../Calendar/CalendarUtil";
import app from "../../../App";

export const Dashboard = () => {

  const profile = useProviderProfile();
  const {getIdTokenClaims} = useAuth0()

  const [events, setEvents] = useState<IEventProto[]>([]);

  // Returns all INSTANCES of appointments returned from the server
  const getAppointments = () => {
    const instances: IEventInstanceProto[] = [];

    events.forEach(series => {
      series.instances?.forEach(instance => {
        if (instance.details?.kenkoDetails?.appointment) {
          instances.push(instance);
        }
      })
    })

    console.log('instances', instances)
    return instances;
  }

  // A predicate which returns whether the appointment is today, or not today
  const dayFilter = (forToday: boolean, appointment: IEventInstanceProto) => {
    const today = new Date();
    const startDate = new Date(appointment.details?.startTimestamp as number);
    return forToday == ((today.getDay() == startDate.getDay()) &&
        (today.getMonth() == startDate.getMonth()) &&
        (today.getFullYear() == startDate.getFullYear()));
  }

  const todaysAppointments = useMemo(() => {
    return getAppointments().filter(appointment => dayFilter(true, appointment));
  }, [events])

  const upcomingAppointments = useMemo(() => {
    return getAppointments()
        .filter(appointment => dayFilter(false, appointment))
        // Maybe there are edge cases where appointments could be in the past? Not sure... 
        .filter(appointment => (appointment.details?.startTimestamp as number) > new Date().getTime());
  }, [events])

  const getEvents = () => {

    const beginningOfToday = new Date();
    beginningOfToday.setHours(0, 0, 0, 0);

    // Midnight 7 days from now
    const beginningOf7thDay = new Date();
    beginningOf7thDay.setDate(beginningOf7thDay.getDate() + 7)
    beginningOf7thDay.setHours(0, 0, 0, 0);


    const request = new GetEventsRequest({
      calendarIds: [{
        businessId: profile.business?.businessId,
        calendarId: '100'
      }],
      startTimestampMs: beginningOfToday.getTime(),
      endTimestampMs: beginningOf7thDay.getTime(),
    })

    SendRpc(getIdTokenClaims, 'get_events', GetEventsRequest.encode(request).finish())
        .then(value => {
          const response = GetEventsResponse.decode(value);
          console.log(response.events);
          setEvents(response.events);
        }).catch(e => {
      alert('error ' + e)
    });
  }

  useEffect(() => {
    getEvents();
  }, [])

  const appointmentBox = (appointment: IEventInstanceProto) => {

    const appointmentStart = new Date(appointment.details?.startTimestamp as number)
    const appointmentLength = spanToMinutes(appointment.details?.startTimestamp as number,
        appointment.details?.endTimestamp as number)

    return (<div className={'DashboardItem'}>
      <div className={'DashboardItemLeft'}>
        <h2>{appointmentStart.getDate()}</h2>
        <span>{appointmentStart.toLocaleDateString(undefined, {month: 'short'})}</span>
      </div>
      <div className={'DashboardItemRight'}>
        <span>{appointmentStart.toLocaleTimeString(undefined, {hour: "numeric", minute: 'numeric'})}</span>
        <span><b>{appointment.details?.title}</b></span>
        <span>{appointment.details?.kenkoDetails?.appointment?.clientName}&nbsp;•&nbsp;{appointmentLength} min</span>
      </div>
    </div>);
  }

  const noAppointmentsFoundBox = () => {
    return (<div className={'DashboardItem'}>
      No appointments found
    </div>);
  }

  return <div className={'VerticalFlex'}>
    <div style={{textAlign: 'start'}}>
      <h1>{profile.businessProfile?.proto?.businessName}</h1>
    </div>

    <div className={'DashboardMain'}>

      <div className={'DashboardColumn'}>

        <div className={'DashboardBox'}>
          <div className={'DashboardBoxHeader'}>
            APPOINTMENTS TODAY
          </div>

          <div className={'DashboardItemsList'}>
            {todaysAppointments.map(appointmentBox)}
            {todaysAppointments.length == 0 && noAppointmentsFoundBox()}
          </div>
        </div>

        <div className={'DashboardBox'}>
          <div className={'DashboardBoxHeader'}>
            UPCOMING APPOINTMENTS
          </div>
          Next 7 days

          <div className={'DashboardItemsList'}>
            {upcomingAppointments.map(appointmentBox)}
            {upcomingAppointments.length == 0 && noAppointmentsFoundBox()}
          </div>
        </div>

      </div>

      <div className={'DashboardColumn'}>
        <div className={'DashboardBox'}>
          <div className={'DashboardBoxHeader'}>
            RECENT TRANSACTIONS
          </div>
        </div>
      </div>
    </div>
  </div>
}