import React, {useMemo, useState} from "react";
import {space} from "../../../../provider_api";
import {useProviderProfile} from "../../../../ProviderProfileProvider";
import {LabeledTextInput} from "../../../../components/input/LabeledTextInput";
import {findError} from "../../../../components/input/InputTypes";
import './Calendar.css';
import {LabeledTimeZoneInput} from "../../../../components/input/LabeledTimeZoneInput";
import {RepeatStrategyEditor} from "./RepeatStrategyEditor";
import {LabeledDropdownInput} from "../../../../components/input/LabeledDropdownInput";
import {filterPractitioners, findProvider, getServicesForProvider} from "./CalendarUtil";
import {LabeledPhoneInput} from "../../../../components/input/LabeledPhoneInput";
import {TimeInput15MinInterval} from "../../../../components/input/TimeInput15MinInterval";
import {LabeledDateInput} from "../../../../components/input/LabeledDateInput";
import RepeatStrategyProto = space.kenko.proto.RepeatStrategyProto;
import IErrorProto = space.kenko.proto.IErrorProto;
import IEventInstanceProto2 = space.kenko.proto.IEventInstanceProto2;
import IKenkoEventDetails = space.kenko.proto.IKenkoEventDetails;
import KenkoEventDetails = space.kenko.proto.KenkoEventDetails;
import {MultiSelect} from "../../../../components/input/MultiSelectInput";
import {BusinessProfile} from "../BusinessProfile/BusinessProfile";
import {LabeledYesNoInput} from "../../../../components/input/LabeledYesNoInput";

type Props = {

  // If this is a new event, this instance should be set to whatever default values
  // are desired. For editing, this is the existing event.
  instance: IEventInstanceProto2,

  // Any errors that may have come down in the rpc response, the paths are like
  // "startTimestamp", "provider", etc.

  errors: IErrorProto[];

  // The UI shows up a little differently depending whether it's an edit or
  // a new instance.
  isEditing: boolean,

  // The range of the current calendar view, this is used to request all
  // the instances of the newly created event. 
  // calendarViewStart: Date,
  // calendarViewEnd: Date,

  onCreate: (event: IEventInstanceProto2) => void,
  onDelete: () => void,
  onCancel: () => void,
}

export type CalendarEventType = 'availability' | 'appointment';

/**
 * This is the overlay for editing and creating events. It itself does not send
 * any RPCs, the caller is responsible for that.
 */
export const EventModal = (props: Props) => {

  const profile = useProviderProfile();

  // Instead of notifying the parent every time any value changes, keep the changes here
  // until a save. 
  const [workingCopy, setWorkingCopy] = useState<IEventInstanceProto2>(props.instance);

  // This is a shorthand that calls setRequest, but used when editing just the event type.
  // The specific event type editors (availability, appointment) use this extensively.
  const updateKenkoEventDetails = (lambda: (event?: IKenkoEventDetails | null) => IKenkoEventDetails): void => {
    setWorkingCopy(instance => {
      return {
        ...instance,
        details: {
          ...instance.details,
          kenkoDetails: lambda(instance.details?.kenkoDetails)
        }
      }
    })
  }

  const eventTypeAsString = (eventType: IKenkoEventDetails | null | undefined): CalendarEventType | undefined => {
    if (!eventType) {
      return undefined;
    }

    // Weird --- the class contains the string oneof but not the interface.
    return (eventType as KenkoEventDetails).EventType;
  }

  const getDefaultEventType = (typeString: CalendarEventType | undefined | null): IKenkoEventDetails | undefined => {
    if (typeString == 'availability') {
      return new KenkoEventDetails({
        availability: {}
      })
    }

    if (typeString == 'appointment') {
      return new KenkoEventDetails({
        appointment: {
          // Awkwardly, the location is an index into the location array and zero
          // is a valid value, but is also the default in proto! This differentiates
          // the thing.
          location: -1
        }
      })
    }

  }


  const officeOptions = useMemo(() => {
    const options: { value: number, label: string }[] = [{value: -1, label: "None"}]
    profile.businessProfile?.locations?.forEach((office, index) => {
      options.push({
        value: index,
        label: office.locationName || ('Location ' + index)
      });
    });
    return options;
  }, [profile.businessProfile]);

  /**
   * Each calendar event type has its own editor. This could be important down the road
   * since event types could get quite different! Think of private appointments vs public
   * classes (w/ lots of students) or even some kind of appointment with 2 teachers.
   *
   * Note that it's safe to assume that in these editors, the correct 'oneof' is already
   * selected in the request proto.
   */
  const AvailabilityBlockEditor = () => {

    // Some hackery here -- on the backend, the available offices is an array, but
    // in practice we only use one value.
    let offices = workingCopy.details?.kenkoDetails?.availability?.offices;
    const selectedOffice = offices && offices.length > 0 ? offices[0] : -1;

    return <>
      <LabeledDropdownInput<string> label={'Choose a provider'}
                                    value={workingCopy.details?.kenkoDetails?.availability?.providerId}
                                    error={findError(props.errors, 'provider')}
                                    options={filterPractitioners(profile.businessProfile?.providers)
                                        .map(provider => {
                                          return {
                                            value: provider.providerId as string,
                                            label: provider.firstName + ' ' + provider.lastName
                                          }
                                        })}
                                    onChange={v => updateKenkoEventDetails(previous => {
                                      return {
                                        ...previous,
                                        availability: {
                                          ...previous?.availability,
                                          providerId: findProvider(profile.businessProfile?.providers, v)?.providerId
                                        }
                                      }
                                    })}/>

      <LabeledDropdownInput<number> label={'Choose a location'}
                                    value={selectedOffice}
                                    error={findError(props.errors, 'offices')}
                                    options={officeOptions}
                                    onChange={v => {
                                      console.log('offices', v);
                                      updateKenkoEventDetails(previous => {
                                        return {
                                          ...previous,
                                          availability: {
                                            ...previous?.availability,
                                            // -1 indicates No office selected.
                                            offices: v < 0 ? [] : [v],
                                          }
                                        }
                                      })
                                    }}/>

      {profile.businessProfile?.proto?.virtualAppointmentInfo &&
          <LabeledYesNoInput label={'Virtual appointments available?'}
                             value={workingCopy.details?.kenkoDetails?.availability?.availableForVirtualAppts || false}
                             onChange={v => {
                               updateKenkoEventDetails(previous => {
                                 return {
                                   ...previous,
                                   availability: {
                                     ...previous?.availability,
                                     availableForVirtualAppts: v,
                                   }
                                 }
                               })
                             }}/>}
    </>
  }

  /**
   * Each calendar event type has its own editor. This could be important down the road
   * since event types could get quite different! Think of private appointments vs public
   * classes (w/ lots of students) or even some kind of appointment with 2 teachers.
   *
   * Note that it's safe to assume that in these editors, the correct 'oneof' is already
   * selected in the request proto.
   *
   * This is useMemo'd because not doing so cause the thing to rerender, and apparently
   * it created different DOM widgets because all of the inputs lost focus immediately
   * after typing a letter.
   */
  const AppointmentBlockEditor = useMemo(() => <>
    <LabeledDropdownInput<string> label={'Choose a provider'}
                                  value={workingCopy.details?.kenkoDetails?.appointment?.providerId}
                                  error={findError(props.errors, 'provider')}
                                  options={filterPractitioners(profile.businessProfile?.providers)
                                      .map(provider => {
                                        return {
                                          value: provider.providerId as string,
                                          label: provider.firstName + ' ' + provider.lastName
                                        }
                                      })}
                                  onChange={v => {
                                    console.log('founded founded', v)
                                    updateKenkoEventDetails(previous => {
                                      return {
                                        ...previous,
                                        appointment: {
                                          ...previous?.appointment,
                                          providerId: findProvider(profile.businessProfile?.providers, v)?.providerId
                                        }
                                      }
                                    })
                                  }}/>

    <LabeledDropdownInput<string> label={'Choose a service'}
                                  value={workingCopy.details?.kenkoDetails?.appointment?.serviceSku}
                                  error={findError(props.errors, 'service')}
                                  options={getServicesForProvider(profile.businessProfile?.practice?.services,
                                      workingCopy.details?.kenkoDetails?.appointment?.providerId).map(value => {
                                    return {
                                      label: `${value.service.name} ($${value.price.price})`,
                                      value: value.service.sku || ''
                                    }
                                  })}
                                  onChange={v => {
                                    console.log('founded founded', v)
                                    updateKenkoEventDetails(previous => {
                                      return {
                                        ...previous,
                                        appointment: {
                                          ...previous?.appointment,
                                          serviceSku: v
                                        }
                                      }
                                    })
                                  }}/>

    <LabeledDropdownInput<number> label={'Choose a location'}
                                  value={workingCopy.details?.kenkoDetails?.appointment?.location || -1}
                                  error={findError(props.errors, 'location')}
                                  options={officeOptions}
                                  onChange={v => {
                                    updateKenkoEventDetails(previous => {
                                      return {
                                        ...previous,
                                        appointment: {
                                          ...previous?.appointment,
                                          // -1 is no office selected
                                          location: v
                                        }
                                      }
                                    })
                                  }}/>

    {profile.businessProfile?.proto?.virtualAppointmentInfo &&
        <LabeledYesNoInput label={'Virtual appointments available?'}
                           value={workingCopy.details?.kenkoDetails?.appointment?.isVirtualAppt || false}
                           onChange={v => {
                             updateKenkoEventDetails(previous => {
                               return {
                                 ...previous,
                                 appointment: {
                                   ...previous?.appointment,
                                   isVirtualAppt: v,
                                 }
                               }
                             })
                           }}/>}

    <LabeledTextInput label={'Client name'}
                      value={workingCopy.details?.kenkoDetails?.appointment?.clientName}
                      error={findError(props.errors, 'clientName')}
                      inputType={'text'}
                      onChange={v => updateKenkoEventDetails(previous => {
                        return {
                          ...previous,
                          appointment: {
                            ...previous?.appointment,
                            clientName: v
                          }
                        }
                      })}/>

    <LabeledPhoneInput label={'Client phone number'}
                       value={workingCopy.details?.kenkoDetails?.appointment?.clientPhone}
                       error={findError(props.errors, 'clientPhone')}
                       onChange={v => updateKenkoEventDetails(previous => {
                         return {
                           ...previous,
                           appointment: {
                             ...previous?.appointment,
                             clientPhone: v
                           }
                         }
                       })}/>

    <LabeledTextInput label={'Client email'}
                      value={workingCopy.details?.kenkoDetails?.appointment?.clientEmail}
                      error={findError(props.errors, 'clientEmail')}
                      inputType={'email'}
                      onChange={v => updateKenkoEventDetails(previous => {
                        return {
                          ...previous,
                          appointment: {
                            ...previous?.appointment,
                            clientEmail: v
                          }
                        }
                      })}/>
  </>, [workingCopy, props.errors]);

  const startTime = new Date(workingCopy.details?.startTimestamp as number)
  const endTime = new Date(workingCopy.details?.endTimestamp as number)

  return (
      <div className="modal-overlay">
        <div className="modal-content">
          <h2>New event</h2>

          <LabeledDropdownInput<string> label={'Event Type'}
                                        value={eventTypeAsString(workingCopy.details?.kenkoDetails)}
                                        error={findError(props.errors, 'eventType')}
                                        options={[
                                          {label: 'Availability', value: 'availability'},
                                          {label: 'Appointment', value: 'appointment'}
                                        ]}
                                        onChange={v => updateKenkoEventDetails(existing => {
                                          return getDefaultEventType(v as CalendarEventType) ?? new KenkoEventDetails();
                                        })}/>

          {workingCopy.details?.kenkoDetails?.availability && <AvailabilityBlockEditor/>}
          {workingCopy.details?.kenkoDetails?.appointment && AppointmentBlockEditor}

          <LabeledDateInput label={'Date'}
                            value={{
                              year: startTime.getFullYear(),
                              month: startTime.getMonth() + 1,
                              date: startTime.getDate(),
                            }}
                            error={findError(props.errors, 'title')}
                            onChange={v => {
                              setWorkingCopy(request => {
                                const updatedStartTime = new Date(startTime);
                                updatedStartTime.setFullYear(v.year, v.month - 1, v.date);

                                const updatedEndTime = new Date(endTime);
                                updatedEndTime.setFullYear(v.year, v.month - 1, v.date);

                                return {
                                  ...request,
                                  details: {
                                    ...request.details,
                                    startTimestamp: updatedStartTime.getTime(),
                                    endTimestamp: updatedEndTime.getTime(),
                                  }
                                }
                              })
                            }}/>

          <TimeInput15MinInterval label={'Start time'}
                                  value={{hour: startTime.getHours(), minute: startTime.getMinutes()}}
                                  error={findError(props.errors, 'startTimestamp')}
                                  onChange={v => {
                                    setWorkingCopy(request => {
                                      const updated = new Date(startTime);
                                      updated.setHours(v.hour);
                                      updated.setMinutes(v.minute);
                                      return {
                                        ...request,
                                        details: {
                                          ...request.details,
                                          startTimestamp: updated.getTime()
                                        }
                                      }
                                    })
                                  }}/>

          <TimeInput15MinInterval label={'End time'}
                                  value={{hour: endTime.getHours(), minute: endTime.getMinutes()}}
                                  error={findError(props.errors, 'endTimestamp')}
                                  onChange={v => {
                                    setWorkingCopy(request => {
                                      const updated = new Date(endTime);
                                      updated.setHours(v.hour);
                                      updated.setMinutes(v.minute);
                                      return {
                                        ...request,
                                        details: {
                                          ...request.details,
                                          endTimestamp: updated.getTime()
                                        }
                                      }
                                    })
                                  }}/>

          <LabeledTimeZoneInput label={'Time zone'}
                                value={workingCopy.timeZoneId}
                                error={findError(props.errors, 'timeZone')}
                                onChange={v => setWorkingCopy(request => {
                                  return {
                                    ...request,
                                    timeZoneId: v
                                  }
                                })}/>

          <RepeatStrategyEditor firstDay={new Date(workingCopy.details?.startTimestamp as number)}
                                proto={workingCopy.repeatStrategy ?? new RepeatStrategyProto()}
                                onChange={v => setWorkingCopy(request => {
                                  return {
                                    ...request,
                                    repeatStrategy: v
                                  }
                                })}/>

          <button onClick={props.onCancel}>Cancel</button>

          <button onClick={() => props.onCreate(workingCopy)}>{props.isEditing ? 'Update' : 'Create'}</button>

          {props.isEditing && <button onClick={props.onDelete}>Delete</button>}

        </div>
      </div>
  );
};

