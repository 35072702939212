import React, {ReactElement} from "react";
import {Link} from "react-router-dom";

type ProfileChecklistItemProps = {
  title: string,
  description: ReactElement,
  completed: boolean,
  buttonLabelIncomplete: string,
  buttonLabelComplete: string,
  link: string
}

export const ProfileChecklistItem = (props: ProfileChecklistItemProps) => {
  return <div className={'CompleteProfileChecklistItem'}>
    <h2>{props.title}</h2>
    <div>{props.description}</div>
    <div className={'CompleteProfileButtonRow'}>
      {!props.completed && <Link to={props.link}>
        <button className={'CompleteProfileChecklistButton'}>{props.buttonLabelIncomplete}</button>
      </Link>}
      {props.completed && <>
        <Link to={props.link}>
          <button className={'CompleteProfileChecklistButtonCompleted'}>{props.buttonLabelComplete}</button>
        </Link>
        <Link to={props.link}>
          <img className={'CompleteProfileEditButton'} alt={'Edit'} src={require('../../../../images/icons/edit.png')}/>
        </Link>
      </>}


    </div>
  </div>
}
